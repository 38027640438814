import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {getImage, StaticImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import Header from "../components/header";
import ActionButton from "../components/action-button";
import BackgroundImage from "gatsby-background-image";
import Footer from "../components/footer";

export default function AboutPage() {

    const { blueBg, yellowBg } = useStaticQuery(
        graphql`
        query {
            blueBg: file(relativePath: { eq: "cyan-blue.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
            yellowBg: file(relativePath: { eq: "cyan-yellow.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgBlueImage = convertToBgImage(getImage(blueBg));
    const bgYellowImage = convertToBgImage(getImage(yellowBg));
    
    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative min-h-screen lg:min-h-3-4-screen w-full" {...bgBlueImage}>
                <div className="absolute w-full">
                    <Header/>
                </div>
                <div className="flex flex-col justify-center items-center py-24 min-h-screen lg:min-h-3-4-screen">
                    <div className="px-10 py-5 leading-tight font-poppins text-white text-center text-2xl lg:text-5xl lg:max-w-4xl">Una aplicación + Tu Headhunter personal= Más valor</div>
                    <div className="px-10 py-5 leading-loose font-poppins text-white text-center text-lg lg:text-xl lg:max-w-4xl">
                        Te ofrecemos todo lo que necesitas para encontrar el talento adecuado, tener una cobertura completa del pipeline de reclutamiento y selección para así tener una visión unificada y ordenada del proceso.
                        Crea una mejor experiencia para tus candidatos y tu equipo.</div>
                    <div className="px-10 py-5"><ActionButton mode="light" to="/lets-start">¡Comienza hoy!</ActionButton></div>
                </div>
            </BackgroundImage>
            <section className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-5 p-8 lg:p-10 justify-center my-20">
                <div className="flex flex-col items-center justify-center h-96 bg-gray-200 font-poppins p-5">
                    <StaticImage imgClassName="rounded-full" className="my-2 w-36" src="../images/map.png" alt="Busca y Atrae"/>
                    <div className="my-2 font-bold text-lg">Busca y Atrae</div>
                    <div className="my-2 text-center">Tu headhunter seleccionará el talento adecuado en el momento adecuado, con un pipeline eficaz.</div>
                </div>
                <div className="flex flex-col items-center justify-center h-96 bg-gray-200 font-poppins p-5">
                    <StaticImage imgClassName="rounded-full" className="my-2 w-36" src="../images/thumbs-up.png" alt="Evalúa"/>
                    <div className="my-2 font-bold text-lg">Evalúa</div>
                    <div className="my-2 text-center">Obtén perfiles previamente evaluados por headhunters especializados en el área que gestionarán todas tus búsquedas.</div>
                </div>
                <div className="flex flex-col items-center justify-center h-96 bg-gray-200 font-poppins p-5">
                    <StaticImage imgClassName="rounded-full" className="my-2 w-36" src="../images/jigsaw.png" alt="Contrata"/>
                    <div className="my-2 font-bold text-lg">Contrata</div>
                    <div className="my-2 text-center">Preselecciona rápidamente a los candidatos, agiliza procesos y toma mejores decisiones de contratación con mayor rapidez.</div>
                </div>
            </section>
            <section className="max-w-7xl mx-auto px-8 py-4 lg:p-10 relative my-20">
                <StaticImage imgStyle={{zIndex: -1}} className="w-5/6 lg:w-3/5" src="../images/software.png" alt="Software"/>
                <div className="z-10 float-right font-poppins w-11/12 lg:w-1/2 bg-gray-200 p-8 lg:p-12 -mt-10 lg:-mt-20 lg:mt-5">
                    <div className="text-2xl font-bold mb-5">Experiencia, incorporada.</div>
                    <div>
                        Sabemos que contratar a las personas adecuadas en el momento adecuado es la diferencia entre el éxito y el fracaso. Hemos pasado por ello.<br/><br/>
                        Por eso hemos diseñado Find IT, para ayudar a personas como tú.<br/><br/>
                        Nuestro sistema facilita aún más que las organizaciones eliminen las complejidades y empiecen más rápido a alcanzar su objetivo de talento.
                    </div>
                </div>
                <div className="clear-both" />
            </section>
            <BackgroundImage Tag="section" className="flex flex-col items-center justify-center h-96 w-full" {...bgYellowImage}>
                <div className="px-10 py-5 leading-tight font-poppins text-white text-center text-3xl lg:text-5xl lg:max-w-4xl">Descubre Find IT tu mismo.</div>
                <div className="px-10 py-5"><ActionButton mode="light" to="/lets-start">¡Empecemos!</ActionButton></div>
            </BackgroundImage>
            <section className="max-w-7xl mx-auto grid grid-cols-1 gap-10 lg:grid-cols-4 justify-center items-center font-poppins p-8 lg:p-10 my-10 text-center">
                <div>
                    <div className="font-bold text-2xl my-5">Asesórate con el Headhunter</div>
                    <div>Reúnete con tu headhunter asignado y explícale el perfil que estás buscando.</div>
                </div>
                <div>
                    <div className="font-bold text-2xl my-5">Gestiona y programa</div>
                    <div>Utiliza nuestra aplicación para programar entrevistas y ver el seguimiento del proceso de selección.</div>
                </div>
                <div>
                    <div className="font-bold text-2xl my-5">Entrevista y evalúa</div>
                    <div>Encuentra el feedback de cada candidato y toma la mejor decisión.</div>
                </div>
                <div>
                    <div className="font-bold text-2xl my-5">Oferta y contrata</div>
                    <div>Envía tu oferta y prepárate para la incorporación de tu nuevo miembro del equipo.</div>
                </div>
            </section>
            <Footer />
        </main>    
    );
}